import React from 'react'

import Layout from "../../components/layout"
import Header from "../../components/header"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

const BudgetPageTemplate = ({data}) => {
  let budgets = data.graphAPI.budgets
  return (
      <Layout>
        <SEO title="Budget Information" />
        <div className="title-section">
            <h1 className="title is-size-1">Budget Information</h1>
        </div>
        <div className="content-align">
          <h2 className="is-size-5" style={{fontWeight: '400'}}>
            Budget for the Current and Prior Years
          </h2>
          {budgets.map( entry => (
            <>
              { entry.budget === null ? (<></>) : (<a style={{marginRight: '1rem', marginBottom: '2rem'}} className="button is-primary" target="_blank" rel="noreferrer noopener" href={entry.budget.documentFile.publicURL}>Approved Budget – {entry.year}</a>)}
            </>
          ))}
          <p><strong>Change in the District's Revenue from {budgets[1].year} to {budgets[0].year}:</strong> $&nbsp;{(budgets[0].revenue-budgets[1].revenue).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}&nbsp;&nbsp;|&nbsp;&nbsp;{((budgets[0].revenue-budgets[1].revenue)/budgets[1].revenue*100).toFixed(2)}%<br />
          <strong>Change in the District's Expenses from {budgets[1].year} to {budgets[0].year}:</strong> $&nbsp;{(budgets[0].expenses-budgets[1].expenses).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}&nbsp;&nbsp;|&nbsp;&nbsp;{((budgets[0].expenses-budgets[1].expenses)/budgets[1].expenses*100).toFixed(2)}%</p>
          <hr />
          <h4>Property Tax Revenue Budgeted for Maintenance & Operations and for Debt Service</h4>
          <table className="table is-striped">
            <thead>
              <tr>
                <td>Year</td>
                <td>Debt Service</td>
                <td>Maintenance & Operations</td>
              </tr>
            </thead>
            <tbody>
            {budgets.map( entry => {
              let rate = data.graphAPI.taxRates.find(rate => rate.year+1 === entry.year)
              console.log(entry.year)
            return (
              <tr>
                <td>{entry.year}</td>
                <td>$&nbsp;{(entry.debtServiceTaxRevenue).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                <td>$&nbsp;{entry.maintenanceOperationsTaxRevenue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
              </tr>
            )})}
            </tbody>
          </table>
        </div>
      </Layout>
  )
}

export default BudgetPageTemplate

export const query = graphql`
    query budgetPageById {
        graphAPI {
            budgets(sort: "year:desc") {
              year
              revenue
              expenses
              maintenanceOperationsTaxRevenue
              debtServiceTaxRevenue
              budget {
                ...publicDocument
              }
            }
            taxRates(sort: "year:desc") {
                year
                maintenanceOperationsRate
                interestSinkingRate
                roadRate
                contractRate
            }
        } 
    }
`


